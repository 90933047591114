<template>
  <div class="PublicTtem_Div">
    <!-- 教研活动 -->
    <template v-if="navActive == 1">
      <div class="list_item active_1"
        :class="{ 'active_3': (currentIndex + 1) % 4 == 0 && currentMargin, 'active_fff': isColorFFF, 'active_bor': activeBor, }"
        @click="toActiveDetail(item)">
        <div class="img_div">
          <div class="img_box">
            <img :src="item.cover_url" alt="">
          </div>
          <div class="time_box">活动时间: {{ item.start_date }} ~ {{ item.end_date }}</div>
          <div v-if="item.status" :class="item.status == 1 ? 'tios_div_box_2_div' : item.status == 2 ? 'tios_div_box_3_div' :
      item.status == 3 ? 'tios_div_box_4_div' : item.status == 4 ? 'tios_div_box_5_div' :
        item.status == 5 ? 'tios_div_box_6_div' : item.status == 6 ? 'tios_div_box_7_div' :
          item.status == 7 ? 'tios_div_box_8_div' : item.status == 8 ? 'tios_div_box_9_div' :
            ''" class="tios_div_box_1_div">
            {{ item.status_name }}</div>
          <!-- <div v-if="item.status == 1" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
          <div v-if="item.status == 2" class="tios_div_box_1_div tios_div_box_2_div">审核中</div>
          <div v-if="item.status == 3" class="tios_div_box_1_div tios_div_box_6_div">已过期</div>
          <div v-if="item.status == 4" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
          <div v-if="item.status == 5" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
          <div v-if="item.status == 6" class="tios_div_box_1_div tios_div_box_7_div">已结束</div>
          <div v-if="item.status == 8" class="tios_div_box_1_div tios_div_box_8_div">待提交</div>
          <div v-if="item.status == 9" class="tios_div_box_1_div tios_div_box_9_div">待发布</div> -->
          <div v-if="item.top == 1" class="tios_div_box_1_div topLeft">置顶</div>
        </div>
        <div class="details_div">
          <div class="temp_class_span_div"> 【 is_personid : {{ item.is_personid }} 】 </div>
          <div class="title_div ellipsis-2">{{ item.name || '-' }}</div>
          <div class="tag_box">
            <div class="tag_item">{{ item.type_name || '-' }}</div>
            <div class="tag_item">{{ item.implement_type || '-' }}</div>
            <template v-if="item.user_activity">
              <div v-if="item.user_activity == 1" class="tag_item tag_2_item">我创建的</div>
              <div v-if="item.user_activity == 2" class="tag_item tag_3_item">我参与的</div>
            </template>
            <template v-else-if="item.my_type_text">
              <div v-if="item.my_type_text == '我创建的'" class="tag_item tag_2_item">我创建的</div>
              <div v-if="item.my_type_text == '我参与的'" class="tag_item tag_3_item">我参与的</div>
            </template>
          </div>
          <template v-for="(ite_2, inde_2) in item.resource">
            <div v-if="inde_2 < 2" :key="inde_2" class="name_div" :class="{ 'active': inde_2 == 0 }">
              <div class="name_img">
                <img :src="ite_2.mime | fileTypeMini" alt="">
              </div>
              <div v-if="ite_2.resource_name" class="name_title ellipsis-1">{{ ite_2.resource_name || '-' }}</div>
              <div v-else class="name_title ellipsis-1">{{ ite_2.name || '-' }}</div>
            </div>
          </template>
          <div class="user_div">
            <div class="user_left">
              <div class="user_img">
                <img :src="item.photo_url" alt="">
              </div>
              <div v-if="item.realname" class="user_name">{{ item.realname || '-' }}</div>
              <div v-else class="user_name">{{ item.org_name || '-' }}</div>
            </div>
            <div class="user_num">{{ item.user_count || 0 }}人参加</div>
            <el-dropdown v-if="handleBtn && (
      (item.is_go_examine_role == 1) ||
      (item.is_update_role == 1) ||
      (item.is_delete_role == 1) ||
      (item.is_examine_role == 1)
    )
      " trigger="hover">
              <span class="name_xiala_1_tag" @click.stop="">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item v-if="item.is_go_examine_role == 1" divided @click.native="activity_to_page(item, 1)">
                  <i class="el-icon-position"></i>去审核
                </el-dropdown-item> -->

                <template v-if="item.is_examine_role == 1">
                  <el-dropdown-item @click.native="activity_to_page(item, 2)">
                    <i class="el-icon-circle-check"></i>审核通过
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="activity_to_page(item, 3)">
                    <i class="el-icon-circle-close"></i>审核不通过
                  </el-dropdown-item>
                </template>

                <el-dropdown-item v-if="item.is_update_role == 1" @click.native="activity_edit(item.id, item)">
                  <i class="el-icon-edit-outline"></i>编辑
                </el-dropdown-item>
                <el-dropdown-item v-if="item.is_delete_role == 1" @click.native="activity_del(item.id, item)">
                  <i class="el-icon-delete"></i>删除
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </template>

    <!-- 教研直播 -->
    <template v-if="navActive == 2">
      <div class="list_item active_2"
        :class="{ 'active_3': (currentIndex + 1) % 4 == 0 && currentMargin, 'active_fff': isColorFFF, 'active_bor': activeBor, }"
        @click="toLiveDetail(item.id, item)">
        <div class="img_div">
          <div class="img_box">
            <img :src="item.cover_url" alt="">
          </div>
          <div v-if="item.status" :class="item.status == 1 ? 'tios_div_box_2_div' : item.status == 2 ? 'tios_div_box_3_div' :
      item.status == 3 ? 'tios_div_box_4_div' : item.status == 4 ? 'tios_div_box_5_div' :
        item.status == 5 ? 'tios_div_box_6_div' : item.status == 6 ? 'tios_div_box_7_div' :
          item.status == 7 ? 'tios_div_box_8_div' : item.status == 8 ? 'tios_div_box_9_div' :
            ''" class="tios_div_box_1_div">
            {{ item.status_text }}</div>
          <!-- <div v-if="item.status == 1" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
          <div v-if="item.status == 2" class="tios_div_box_1_div tios_div_box_2_div">审核中</div>
          <div v-if="item.status == 3" class="tios_div_box_1_div tios_div_box_6_div">已过期</div>
          <div v-if="item.status == 4" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
          <div v-if="item.status == 5" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
          <div v-if="item.status == 6" class="tios_div_box_1_div tios_div_box_7_div">已结束</div> -->
          <!-- <div v-if="item.status == 1" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
          <div v-if="item.status == 2" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
          <div v-if="item.status == 3" class="tios_div_box_1_div tios_div_box_7_div">已结束</div> -->
          <div v-if="item.top == 1" class="tios_div_box_1_div topLeft">置顶</div>
        </div>
        <div class="details_div">
          <div class="temp_class_span_div"> 【 is_personid : {{ item.is_personid }} 】 </div>
          <div class="temp_class_span_div"> 【 live : {{ item.live }} 】 </div>
          <div v-if="item.title" class="title_div ellipsis-2">{{ item.title || '-' }}</div>
          <div v-else class="title_div ellipsis-2">{{ item.name || '-' }}</div>
          <div class="tag_box">
            <div class="tag_time_box">开播时间: {{ item.start_date }} {{ item.start_time }}</div>
            <template v-if="item.user_activity">
              <div v-if="item.user_activity == 1" class="tag_item tag_2_item">我创建的</div>
              <div v-if="item.user_activity == 2" class="tag_item tag_3_item">我参与的</div>
            </template>
            <template v-else-if="item.my_type_text">
              <div v-if="item.my_type_text == '我创建的'" class="tag_item tag_2_item">我创建的</div>
              <div v-if="item.my_type_text == '我参与的'" class="tag_item tag_3_item">我参与的</div>
            </template>
          </div>
          <template v-for="(ite_2, inde_2) in item.resource">
            <div v-if="inde_2 < 2" :key="inde_2" class="name_div" :class="{ 'active': inde_2 == 0 }">
              <div class="name_img">
                <img :src="ite_2.mime | fileTypeMini" alt="">
              </div>
              <div v-if="ite_2.resource_name" class="name_title ellipsis-1">{{ ite_2.resource_name || '-' }}</div>
              <div v-else class="name_title ellipsis-1">{{ ite_2.name || '-' }}</div>
            </div>
          </template>
          <div class="user_div">
            <div class="user_left">
              <div class="user_img">
                <img :src="item.photo_url" alt="">
              </div>
              <div v-if="item.username" class="user_name">{{ item.username || '-' }}</div>
              <div v-else class="user_name">{{ item.realname || '-' }}</div>
            </div>
            <div v-if="item.org_name" class="user_num">{{ item.org_name || '-' }}</div>
            <div v-else class="user_num">{{ item.group_name || '-' }}</div>
            <el-dropdown v-if="handleBtn &&
      ((item.is_update_role == 1) ||
        (item.is_delete_role == 1)) ||
      (item.is_examine_role == 1)" trigger="hover">
              <span class="name_xiala_1_tag" @click.stop="">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <template v-if="item.is_examine_role == 1">
                  <el-dropdown-item @click.native="live_to_page(item, 2)">
                    <i class="el-icon-circle-check"></i>审核通过
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="live_to_page(item, 3)">
                    <i class="el-icon-circle-close"></i>审核不通过
                  </el-dropdown-item>
                </template>
                <el-dropdown-item v-if="item.is_update_role == 1" @click.native="live_edit(item.id, item)">
                  <i class="el-icon-edit-outline"></i>编辑
                </el-dropdown-item>
                <el-dropdown-item v-if="item.is_delete_role == 1" @click.native="live_del(item.id, item)">
                  <i class="el-icon-delete"></i>删除
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </template>

    <!-- 研修视频 -->
    <template v-if="navActive == 3">
      <div class="list_item active_1"
        :class="{ 'active_3': (currentIndex + 1) % 4 == 0 && currentMargin, 'active_fff': isColorFFF, 'active_bor': activeBor, }"
        @click="toCourseDetail(item)">
        <div class="img_div">
          <div class="img_box">
            <img :src="item.cover_url" alt="">
          </div>
          <div v-if="item.web_status && item.web_status != 7" :class="item.web_status == 1 ? 'tios_div_box_2_div' : item.web_status == 2 ? 'tios_div_box_3_div' :
      item.web_status == 3 ? 'tios_div_box_4_div' : item.web_status == 4 ? 'tios_div_box_5_div' :
        item.web_status == 5 ? 'tios_div_box_6_div' : item.web_status == 6 ? 'tios_div_box_7_div' :
          item.web_status == 7 ? 'tios_div_box_8_div' : item.web_status == 8 ? 'tios_div_box_9_div' :
            ''" class="tios_div_box_1_div">
            {{ item.web_status_str }}</div>
          <!-- <div v-if="item.status == 2" class="tios_div_box_1_div tios_div_box_2_div">审核中</div>
          <div v-if="item.status == 3" class="tios_div_box_1_div tios_div_box_6_div">已过期</div>
          <div v-if="item.status == 4" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
          <div v-if="item.status == 5" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
          <div v-if="item.status == 6" class="tios_div_box_1_div tios_div_box_7_div">已结束</div> -->
          <div v-if="item.top == 1" class="tios_div_box_1_div topLeft">置顶</div>
        </div>
        <div class="details_div">
          <div class="temp_class_span_div"> 【 is_personid : {{ item.is_personid }} 】 </div>
          <div class="temp_class_span_div"> 【 live : {{ item.live }} 】 </div>
          <div class="title_div ellipsis-2">{{ item.name || '-' }}</div>
          <div class="tag_box">
            <div class="tag_item">{{ item.stage_text || '-' }}</div>
            <div class="tag_item">{{ item.subject_text || '-' }}</div>
            <div v-if="item.type_text" class="tag_item">{{ item.type_text || '-' }}</div>
            <template v-if="item.user_activity">
              <div v-if="item.user_activity == 1" class="tag_item tag_2_item">我创建的</div>
              <div v-if="item.user_activity == 2" class="tag_item tag_3_item">我参与的</div>
            </template>
            <template v-else-if="item.my_type_text">
              <div v-if="item.my_type_text == '我创建的'" class="tag_item tag_2_item">我创建的</div>
              <div v-if="item.my_type_text == '我参与的'" class="tag_item tag_3_item">我参与的</div>
            </template>
          </div>
          <template v-for="(ite_2, inde_2) in item.resource">
            <div v-if="inde_2 < 2" :key="inde_2" class="name_div" :class="{ 'active': inde_2 == 0 }">
              <div class="name_img">
                <img :src="ite_2.mime | fileTypeMini" alt="">
              </div>
              <div v-if="ite_2.resource_name" class="name_title ellipsis-1">{{ ite_2.resource_name || '-' }}</div>
              <div v-else class="name_title ellipsis-1">{{ ite_2.name || '-' }}</div>
            </div>
          </template>
          <div class="user_div">
            <div class="user_left">
              <div class="user_img">
                <img :src="item.photo_url" alt="">
              </div>
              <div class="user_name">{{ item.author || '-' }}</div>
            </div>
            <div v-if="item.org_name" class="user_num">{{ item.org_name || '-' }}</div>
            <div v-else class="user_num">{{ item.teaching_studio_name || '-' }}</div>
            <el-dropdown v-if="handleBtn && (
      (item.is_publish_role == 1) ||
      (item.is_permiss_role == 1) ||
      (item.is_update_role == 1) ||
      (item.is_delete_role == 1) ||
      (item.is_examine_role == 1)
    )" trigger="hover">
              <span class="name_xiala_1_tag" @click.stop="">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <template v-if="item.is_examine_role == 1">
                  <el-dropdown-item divided @click.native="course_to_page(item, 2)">
                    <i class="el-icon-circle-check"></i>审核通过
                  </el-dropdown-item>
                  <el-dropdown-item divided @click.native="course_to_page(item, 3)">
                    <i class="el-icon-circle-close"></i>审核不通过
                  </el-dropdown-item>
                </template>

                <!-- <el-dropdown-item v-if="item.is_publish_role == 1" divided @click.native="course_issue(item.id, item)">
                  <i class="el-icon-document-checked"></i>发布
                </el-dropdown-item>
                <el-dropdown-item v-if="item.is_permiss_role == 1" divided
                  @click.native="course_prepare(item.id, item.type, item.teaching_form, item, item.teaching_studio_id)">
                  <i class="el-icon-collection"></i>备课
                </el-dropdown-item> -->

                <el-dropdown-item v-if="item.is_update_role == 1" @click.native="course_edit(item.id, item)">
                  <i class="el-icon-edit-outline"></i>编辑
                </el-dropdown-item>
                <el-dropdown-item v-if="item.is_delete_role == 1" @click.native="course_del(item.id, item)">
                  <i class="el-icon-delete"></i>删除
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import * as researchApi from '@/api/researchApi.js';
export default {
  name: "PublicTtemDiv",
  props: {
    // 工作室模块
    isStudioModule: {
      default: "",
    },
    // 教研组模块
    isGroupModule: {
      default: "",
    },
    // 操作按钮
    handleBtn: {
      type: Boolean,
      default: false,
    },
    // 边框
    activeBor: {
      type: Boolean,
      default: false,
    },
    // 是否字体白色
    isColorFFF: {
      type: Boolean,
      default: false,
    },
    // 当前索引
    currentIndex: {
      type: [String, Number],
      default: "",
    },
    // 在第4块 有 Margin
    currentMargin: {
      type: Boolean,
      default: true,
    },
    // 当前模块
    navActive: {
      type: [String, Number],
      default: "",
      // 1 教研活动
      // 2 教研直播
      // 3 研修视频
    },
    // 数据
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      user_id: localStorage.getItem("id") || "",
      yun_url: ""
    }
  },
  computed: {},

  components: {},

  methods: {
    // 课程备课
    course_prepare(id, tid, fid, item, studioId) {
      this.$emit('toDetailPage', id, tid, fid, item, studioId);
    },
    // 课程发布
    course_issue(id, item) {
      this.$emit('pushClick', id, item);
    },

    // 课程编辑
    course_edit(id, item) {
      if (item.is_personid == 1) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/updataCourseInfo?id=${id}&is_personid=1`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push({
            path: "/updataCourseInfo",
            query: { id: id, is_personid: 1 },
          });
        }
      };
      if (item.is_personid == 2) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/updataCourseInfoSet?id=${id}&is_personid=2`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push({
            path: "/updataCourseInfoSet",
            query: { id: id, is_personid: 2 },
          });
        }
      };
      return
      this.$emit('editClick_1', id);
    },
    // 活动编辑
    activity_edit(id, item) {
      if (item.is_personid == 1) {
        this.$router.push({
          path: "/newActivity",
          query: { groupId: item.group_id, id: id },
        });
      }
      if (item.is_personid == 2) {
        if (this.$route.name == 'ActivityManage') {
          this.$router.push({
            path: "/CreateActivity",
            query: { studioId: item.teaching_studio_id, id: id },
          });
        }
        if (this.$route.name == 'TrainActivityIndex') {
          this.$router.push({
            path: "/TrainActivityIndexSet",
            query: { studioId: item.teaching_studio_id, id: id },
          });
        }
      }
      return
      this.$emit('researchActivityEditClick', id);
    },
    // 直播编辑
    live_edit(id, item) {
      if (item.is_personid == 1) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + '/#/createGroupNewLive?id=' + id + '&is_personid=1&groupId=' + item.group_id)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push({
            path: "/createGroupNewLive",
            query: { id: id, is_personid: 1, groupId: item.group_id },
          });
        }
      };
      if (item.is_personid == 2) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + '/#/CreateLiveSet?id=' + id + '&is_personid=2&studioId=' + item.teaching_studio_id + '&editStatus=' + item.status)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push({
            path: "/CreateLiveSet",
            query: { id: id, is_personid: 2, studioId: item.teaching_studio_id, editStatus: item.status },
          });
        }
      };
    },

    // 课程删除
    course_del(id, item) {
      this.$emit('delClick_1', id, item);
    },
    // 活动删除
    activity_del(id, item) {
      this.$emit('delClick_2', id, item);
    },
    // 直播 输入 理由
    live_open_AffirmFn(item, num) {
      this.$prompt('请输入您的理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入您的理由，不能为空!',
        inputValidator: (valtext) => {
          if (valtext && valtext.length >= 2) {
            return true;
          };
          return false;
        }
      }).then(({ value }) => {
        switch (Number(num)) {
          case 2:
            this.live_to_request(item, 2, value);
            break;
          case 3:
            this.live_to_request(item, 3, value);
            break;
          default:
            break;
        }
      }).catch(() => {

      });
    },
    // 直播 确认理由
    live_to_request(item, num, value) {
      if (item.is_personid == 1) {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 4,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_1_group_live_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('live_RefreshPage');
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_1_group_live_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('live_RefreshPage');
            })
            break;
          default:
            break;
        }
      } else {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 4,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_1_audit_status(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('live_RefreshPage');
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_1_audit_status(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('live_RefreshPage');
            })
            break;
          default:
            break;
        }
      }
    },
    // 直播 操作 跳转
    live_to_page(item, num) {
      switch (Number(num)) {
        case 2:
          this.live_open_AffirmFn(item, 2);
          break;
        case 3:
          this.live_open_AffirmFn(item, 3);
          break;
        default:
          break;
      }
    },

    // 活动 输入 理由
    activity_open_AffirmFn(item, num) {
      this.$prompt('请输入您的理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入您的理由，不能为空!',
        inputValidator: (valtext) => {
          if (valtext && valtext.length >= 2) {
            return true;
          };
          return false;
        }
      }).then(({ value }) => {
        switch (Number(num)) {
          case 2:
            this.activity_to_request(item, 2, value);
            break;
          case 3:
            this.activity_to_request(item, 3, value);
            break;
          default:
            break;
        }
      }).catch(() => {

      });
    },
    // 活动 确认理由
    activity_to_request(item, num, value) {
      if (item.is_personid == 1) {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 4,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_2_my_group_activity_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('activity_RefreshPage');
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_2_my_group_activity_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('activity_RefreshPage');
            })
            break;
          default:
            break;
        }
      } else {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 4,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_2_my_audit_status(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('activity_RefreshPage');
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_activity_my_2_my_audit_status(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('activity_RefreshPage');
            })
            break;
          default:
            break;
        }
      }
    },
    // 活动 操作 跳转
    activity_to_page(item, num) {
      switch (Number(num)) {
        case 1:
          this.toActiveDetail(item);
          break;
        case 2:
          this.activity_open_AffirmFn(item, 2);
          break;
        case 3:
          this.activity_open_AffirmFn(item, 3);
          break;
        default:
          break;
      }
    },

    // 课程 输入 理由
    course_open_AffirmFn(item, num) {
      this.$prompt('请输入您的理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入您的理由，不能为空!',
        inputValidator: (valtext) => {
          if (valtext && valtext.length >= 2) {
            return true;
          };
          return false;
        }
      }).then(({ value }) => {
        switch (Number(num)) {
          case 2:
            this.course_to_request(item, 2, value);
            break;
          case 3:
            this.course_to_request(item, 3, value);
            break;
          default:
            break;
        }
      }).catch(() => {
      });
    },
    // 课程 确认理由
    course_to_request(item, num, value) {
      if (item.is_personid == 1) {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 2,
              is_personid: item.is_personid,
              reason: value,
            };
            researchApi.get_course_my_2_my_group_course_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('course_RefreshPage');
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_course_my_2_my_group_course_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('course_RefreshPage');
            })
            break;
          default:
            break;
        }
      } else {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 4,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_course_my_2_my_group_course_3_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('course_RefreshPage');
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
            };
            researchApi.get_course_my_2_my_group_course_3_review(params).then(res => {
              this.$message.success("操作成功");
              this.$emit('course_RefreshPage');
            })
            break;
          default:
            break;
        }
      }
    },
    // 课程 操作 跳转
    course_to_page(item, num) {
      switch (Number(num)) {
        case 2:
          this.course_open_AffirmFn(item, 2);
          break;
        case 3:
          this.course_open_AffirmFn(item, 3);
          break;
        default:
          break;
      }
    },

    // 直播删除
    live_del(id, item) {
      this.$emit('delClick_3', id, item);
    },
    // 跳转直播详情
    toLiveDetail(id, item) {
      if (this.isStudioModule == 1) {
        this.$emit('toPageEvent_3', item);
        return false;
      };
      if (this.isGroupModule == 1) {
        this.$emit('toPageEvent_live', item);
        return false;
      };
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + item.go_url)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        window.open(item.go_url, "_self");
      }
    },
    // 跳转活动详情
    toActiveDetail(item) {
      if (this.isStudioModule == 1) {
        this.$emit('toPageEvent_4', item);
        return false;
      };
      if (this.isGroupModule == 1) {
        this.$emit('toPageEvent_5', item);
        return false;
      };
      window.open(item.go_url, "_self");
    },
    // 跳转课程
    toCourseDetail(item) {
      if (this.isStudioModule == 1) {
        this.$emit('toPageEvent_3', item);
        return false;
      };
      if (this.isGroupModule == 1) {
        this.$emit('toPageEvent_7', item);
        return false;
      };
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + item.go_url)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        window.open(item.go_url, "_self");
      }
    },
  },

  destroyed() {
  },

  mounted() {
  },

  created() {
    if (localStorage.getItem('yun_url')) {
      this.yun_url = localStorage.getItem('yun_url')
    }
  },
}
</script>

<style lang="scss" scoped>
div,
span {
  box-sizing: border-box;
}

.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 90px;
  height: 62px;
  top: -20px;
  right: -36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  transform: rotate(45deg);
  background-color: #ff8201;
  color: #fff;

  &.tios_div_box_2_div {
    background-color: #0080ff;
  }

  &.tios_div_box_3_div {
    background-color: #ff8201;
  }

  &.tios_div_box_4_div {
    background-color: #999999;
  }

  &.tios_div_box_5_div {
    background-color: #0080ff;
  }

  &.tios_div_box_6_div {
    background-color: #ff0607;
  }

  &.tios_div_box_7_div {
    background-color: #dddddd;
    color: #999;
  }

  &.tios_div_box_8_div {
    background-color: #0ba216;
  }

  &.tios_div_box_9_div {
    background-color: #0ba216;
  }
}

.topLeft {
  top: -20px;
  left: -36px;
  transform: rotate(-45deg);
  background: #ff0607;
}

.list_item {
  width: 335px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
  }

  &.active_1 {
    height: 416px;
  }

  &.active_2 {
    height: 406px;
  }

  &.active_3 {
    margin-right: 0px;
  }

  .img_div {
    width: 100%;
    height: 188px;
    position: relative;

    .img_box {
      width: 100%;
      height: 100%;

      >img {
        width: 100%;
        height: 100%;
      }
    }

    .time_box {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      text-align: left;
      padding-left: 20px;
      color: rgba(255, 255, 255, 1);
      background: rgba(0, 0, 0, 0.6);
    }

    .status_box_2 {
      position: absolute;
      width: 56px;
      height: 24px;
      top: 8px;
      right: 0;
      background: rgba(255, 128, 41, 1);
      border-radius: 4px 0px 0px 4px;
      font-size: 12px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
      color: rgba(255, 255, 255, 1);

      &.status_box_1 {
        background-color: #0080ff;
      }

      &.status_box_3 {
        background-color: #cecece;
      }
    }
  }

  .details_div {
    padding: 0 20px;
    text-align: left;

    .title_div {
      margin-top: 22px;
      font-size: 15px;
      font-weight: bold;
      color: #111a38;
    }

    .tag_box {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .tag_item {
        padding: 0 8px;
        height: 24px;
        background: #e5f4ff;
        border-radius: 4px;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #0493ff;
        margin-right: 10px;
      }

      .tag_2_item {
        background-color: #3489fe;
        color: #fff;
      }

      .tag_3_item {
        background-color: #52b876;
        color: #fff;
      }

      .tag_time_box {
        height: 24px;
        line-height: 24px;
        text-align: left;
        font-size: 13px;
        font-weight: normal;
        color: rgba(102, 102, 102, 1);
        margin-right: 10px;
      }
    }

    .name_div {
      display: flex;
      align-items: center;
      margin-top: 12px;

      &.active {
        margin-top: 16px;
      }

      .name_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;

        >img {
          width: 20px;
          height: 20px;
        }
      }

      .name_title {
        font-size: 14px;
        line-height: 20px;
        color: rgba(102, 102, 102, 1);
        margin-left: 10px;
      }
    }

    .user_div {
      width: 100%;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 0;
      bottom: 24px;

      .name_xiala_1_tag {
        color: #aaaaaa;
        width: 30px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
      }

      .user_left {
        display: flex;
        align-items: center;
      }

      .user_img {
        width: 24px;
        height: 24px;

        >img {
          width: 24px;
          height: 24px;
          border-radius: 4px;
        }
      }

      .user_name {
        max-width: 150px;
        margin-left: 12px;
        margin-right: 12px;
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .user_num {
        flex: 1;
        text-align: right;
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }
  }
}

.list_item.active_fff {
  .title_div {
    color: #fff !important;
  }

  .tag_time_box {
    color: #fff !important;
  }

  .name_title {
    color: #fff !important;
  }

  .user_name {
    color: #fff !important;
  }

  .user_num {
    color: #fff !important;
  }
}

.list_item.active_bor {
  border: 1px solid rgba(238, 242, 251, 1);
}

.list_item:nth-of-type(4n) {
  margin-right: 0px;
}
</style>